import HomeView from 'presentation/view/home/HomeView'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import moment from 'moment'
import 'moment/locale/ru'
import { ThemeProvider } from 'presentation/shared/ThemeProvider'
import PrivatePolicyView from 'presentation/view/private/PrivatePolicy'
import HelpAccountDeleteView from 'presentation/view/help/account/HelpAccountDelete'
import PrivatePolicyEnView from 'presentation/view/private/PrivatePolicyEn'
import MobileStoreRedirectView from 'presentation/view/mobilestore/MobileStoreRedirectView'

moment().locale('ru')

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomeView />,
	},
	{
		path: '/app',
		element: <MobileStoreRedirectView />,
	},
	{
		path: '/privacy',
		element: <PrivatePolicyView />,
	},
	{
		path: '/privacy/ru',
		element: <PrivatePolicyView />,
	},
	{
		path: '/privacy/en',
		element: <PrivatePolicyEnView />,
	},
	{
		path: '/help/account/delete',
		element: <HelpAccountDeleteView />,
	},
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<ThemeProvider defaultTheme='dark' storageKey='ui-theme'>
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	</ThemeProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

declare global {
	interface BigInt {
		toJSON(): number
	}
}

BigInt.prototype.toJSON = function () {
	return Number(this)
}

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (
	localStorage.getItem('color-theme') === 'dark' ||
	(!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
	document.documentElement.classList.add('dark')
} else {
	document.documentElement.classList.remove('dark')
}
