import React from 'react'
import NavBarView from '../navigation/NavigationBarView'

export default function PrivatePolicyView() {
	return (
		<div className='flex min-h-screen w-full flex-col'>
			<NavBarView />
			<div className='container px-5 mx-auto xl:px-5 max-w-screen-lg !pt-0'>
				<div className='relative sm:rounded-lg overflow-hidden my-5 mx-auto max-w-screen-lg mb-32'>
					<div className='w-full max-w-screen-xl'>
						<div className='relative overflow-hidden border sm:rounded-lg p-5'>
							<code className='block'>
								<p>Политика конфиденциальности</p>
								<br />
								Мы уважаем вашу конфиденциальность и обязуемся защищать ее, соблюдая настоящую политику
								конфиденциальности (Политика). В настоящей Политике описываются типы информации, которую мы можем
								собирать у вас или которую вы можете предоставить (Личная информация) в мобильном приложении
								FoodTwoSteps (Мобильное приложение или Сервис) и любых его сопутствующие продукты и услуги (далее
								Услуги), а также наши методы сбора, использования, поддержания, защиты и раскрытия этой Личной
								информации. В нем также описываются доступные для в отношении использования нами вашей Личной
								информации, а также о том, как вы можете получить доступ к ней и обновить ее.
								<br />
								<br />
								Настоящая Политика является юридически обязывающим соглашением между вами (Пользователь, вы или ваш) и
								разработчиком мобильного приложения (Оператор, мы, нас или наш). Если вы заключаете настоящую Политику
								от имени предприятия или другого юридического лица, вы заявляете, что у вас есть полномочия связывать
								такое лицо настоящей Политикой, в этом случае В этом случае термины Пользователь, вы или ваш относятся к
								такому юридическому лицу. Если у вас нет таких полномочий или если вы не согласны с условиями настоящей
								Политики, вы не должны принимать настоящую Политику и не можете получать доступ и использовать Мобильным
								приложением и Услугами. Получая доступ и используя Мобильное приложение и Услуги, вы признаете что вы
								прочитали, поняли и согласны соблюдать условия настоящей Политики. Настоящая Политика не
								распространяется к практике компаний, которыми мы не владеем и не контролируем, а также к лицам, которых
								мы не нанимаем и которыми не управляем. управляем.
								<br />
								<br />
								<p>Автоматический сбор информации</p>
								<br />
								Когда вы используете Мобильное приложение, наши серверы автоматически записывают информацию, которую
								отправляет ваше устройство. Эти данные могут включать в себя такую информацию, как IP-адрес и
								местоположение вашего устройства, имя и версия устройства, тип и версия операционной системы, языковые
								предпочтения. тип и версия операционной системы, языковые предпочтения, информация, которую вы ищете в
								Мобильном приложении, время и дата доступа время и дата доступа, а также другие статистические данные.
								<br />
								<br />
								Информация, собранная автоматически, используется только для выявления потенциальных случаев
								злоупотребления и создания статистической информации об использовании Мобильного приложения и Услуг. Эта
								статистическая информация не агрегируется таким образом, чтобы идентифицировать конкретного Пользователя
								системы.
								<br />
								<br />
								Сбор личной информации
								<br />
								<br />
								Вы можете получить доступ и использовать Мобильное приложение и Услуги, не сообщая нам, кто вы, и не
								раскрывая никакой информации, по которой кто-то может идентифицировать вас как конкретное, поддающееся
								идентификации лицо. Однако если вы захотите воспользоваться некоторыми функциями, предлагаемыми в
								Мобильном приложении, вас могут попросить предоставить определенную личную информацию (например, ваше
								имя и фамилию). информацию (например, ваше имя и адрес электронной почты).
								<br />
								<br />
								Мы получаем и храним любую информацию, которую вы сознательно предоставляете нам при создании учетной
								записи, публикации контента, или заполнении любых форм в Мобильном приложении. При необходимости эта
								информация может включать следующее:
								<br />
								<br />
								- Данные учетной записи (например, имя пользователя, уникальный идентификатор пользователя, пароль и т.
								д.)
								<br />
								- Контактная информация (например, адрес электронной почты и т. д.)
								<br />
								- Основная личная информация (например, имя, страна проживания и т. д.)
								<br />
								- Геолокационные данные вашего устройства (например, широта и долгота)
								<br />
								- Определенные функции мобильного устройства (например, контакты, календарь, галерея и т. д.).
								<br />
								<br />
								Вы можете не предоставлять нам свои личные данные, но тогда вы не сможете воспользоваться
								воспользоваться некоторыми функциями Мобильного приложения. Пользователи, которые не уверены в том,
								какая информация является обязательной, могут связаться с нами.
								<br />
								<br />
								Конфиденциальность детей
								<br />
								<br />
								Мы сознательно не собираем личную информацию детей младше 18 лет. Если вам еще не исполнилось 18 лет,
								пожалуйста, не предоставляйте Личную информацию через Мобильное приложение и Услуги. Если у васу вас
								есть основания полагать, что ребенок в возрасте до 18 лет предоставил нам Личную информацию
								черезМобильное приложение и Услуги, пожалуйста, свяжитесь с нами и попросите удалить Личную информацию
								такого ребенкаиз наших Услуг.
								<br />
								<br />
								Мы призываем родителей и законных опекунов следить за использованием Интернета их детьми и
								способствовать соблюдению настоящей Политику, инструктируя своих детей никогда не предоставлять Личную
								информацию через Мобильное приложение и Услуги без их разрешения.Услуги без их разрешения.Мы также
								просим всех родителей и законных опекунов, осуществляющих надзор задетей, приняли необходимые меры
								предосторожности для обеспечения того, чтобы их дети были проинструктированы о том, чтобы никогда не
								предоставлять Личную информацию в Интернете без их разрешения.
								<br />
								<br />
								Использование и обработка собранной информации
								<br />
								<br />
								Мы выступаем в качестве контроллера и обработчика данных при работе с Личной информацией, за исключением
								случаев, когда мы заключили с вами соглашение об обработке данных с вами, в этом случае вы будете
								контролером данных, а мы - процессором. обработчиком данных.
								<br />
								<br />
								Наша роль может также отличаться в зависимости от конкретной ситуации, связанной с Личной информацией.
								Мы действуем в в качестве контролера данных, когда просим вас предоставить Личную информацию,
								необходимую для обеспечения вашего доступа и использования Мобильного приложения и Услуг. В таких
								случаях мы являемся контролером данных поскольку мы определяем цели и способы обработки Личной
								информации.
								<br />
								<br />
								Мы выступаем в качестве обработчика данных в ситуациях, когда вы предоставляете Личную информацию через
								Мобильное приложение и Услуги. Мы не владеем, не контролируем и не принимаем решений в отношении
								предоставленной Личной информации. Информация, и такая Личная информация обрабатывается только в
								соответствии с вашими инструкциями. В таких случаях Пользователь, предоставляющий Личную информацию,
								выступает в качестве контроллера данных.
								<br />
								<br />
								Для того чтобы сделать Мобильное приложение и Услуги доступными для вас, или для выполнения юридических
								обязательств, нам может необходимо собирать и использовать определенную Личную информацию. Если вы не
								предоставите запрашиваемую нами информацию, мы, возможно, не сможем предоставить вам запрашиваемые
								продукты или услуги. Любая информация, которую мы собираем от вас, может быть использована для следующих
								целей:
								<br />
								<br />
								- Создание и управление учетными записями пользователей
								<br />
								- Отправка обновлений продуктов и услуг
								<br />
								- Обеспечение соблюдения условий и политики
								<br />
								- Запуск и эксплуатация мобильного приложения и услуг
								<br />
								<br />
								Обработка вашей Личной информации зависит от того, как вы взаимодействуете с Мобильным приложением и
								Услугами,вашего местонахождения в мире и если применяется одно из следующих условий: (i) вы дали свое
								согласие для одной или нескольких конкретных целей; (ii) предоставление информации необходимо для
								выполнения настоящей Политикис вами и/или для выполнения преддоговорных обязательств; (iii) обработка
								необходима для выполнения (iii) обработка необходима для выполнения юридического обязательства, которому
								вы подлежите; (iv) обработка связана с задачей, выполняемой вобщественных интересах или в рамках
								осуществления официальных полномочий, возложенных на нас; (v) обработка необходима для (v) обработка
								необходима для соблюдения законных интересов, преследуемых нами или третьей стороной. Мы также можем
								объединять или агрегировать некоторыенекоторые из ваших личных данных, чтобы лучше обслуживать вас, а
								также для улучшения и обновления нашего мобильного приложения и услуг.Услуги.
								<br />
								<br />
								Обратите внимание, что в соответствии с некоторыми законодательными актами нам может быть разрешено
								обрабатывать информацию до тех пор, пока вы не возразите против такой обработки отказавшись от нее, без
								необходимости полагаться на согласие или любую другую правовую основу. В любом случае мы будем будем
								рады разъяснить конкретную правовую основу, которая применяется к обработке, и, в частности, является ли
								предоставление предоставление личной информации является требованием закона или договора, или
								требованием, необходимым для заключения договора.
								<br />
								<br />
								Управление информацией
								<br />
								<br />
								Вы можете удалить некоторые личные данные, которые мы имеем о вас. Личная информация, которую вы можете
								удалить может меняться по мере изменения Мобильного приложения и Услуг. Однако, когда вы удаляете Личную
								информацию, мы можем сохранить копию непересмотренной Личной информации в наших записях на срок,
								необходимый для выполнения обязательств перед нашими аффилированными лицами и партнерами, а также для
								целей, описанных ниже. Если вы хотите удалить свою Личную информацию или навсегда удалить свою учетную
								запись, вы можете сделать это на странице настроек вашей учетной записи в Мобильном приложении.
								<br />
								<br />
								Раскрытие информации
								<br />
								<br />
								В зависимости от запрашиваемых услуг или в случае необходимости завершения любой транзакции или
								предоставления любой услуги, которую вы мы можем передавать вашу информацию нашим подрядным компаниям и
								поставщикам услуг (далее - Поставщики услуг), на которых мы полагаемся для оказания помощи в работе
								Мобильного приложения и Услуги, доступные вам, и чьи политики конфиденциальности соответствуют нашим или
								которые согласны соблюдать наши политики в отношении Личной информации. Мы не будем передавать
								персональную информацию третьими лицами и не будем передавать какую-либо информацию неаффилированным
								третьим лицам.
								<br />
								<br />
								Поставщики услуг не имеют права использовать или раскрывать вашу информацию, за исключением случаев,
								когда это необходимо для выполнения услуг от нашего имени или для выполнения требований
								законодательства. Поставщики услуг получают информацию, которая им необходимую только для выполнения их
								функций, и мы не разрешаем им использовать или раскрывать какую-либо предоставленную информацию для
								собственных маркетинговых или иных целей.
								<br />
								<br />
								Сохранение информации
								<br />
								<br />
								Мы будем хранить и использовать вашу Личную информацию в течение периода, необходимого для выполнения
								наших юридическихобязательств, до тех пор, пока ваша учетная запись пользователя остается активной, для
								обеспечения соблюдения нашей Политики, разрешения споров, и если толькоболее длительный срок хранения
								требуется или разрешен законом.
								<br />
								<br />
								Мы можем использовать любые агрегированные данные, полученные на основе или включающие вашу Личную
								информацию после того, как вы обновите илиудаления, но не таким образом, чтобы идентифицировать вас
								лично.По истечении срока хранения Личная информацияИнформация должна быть удалена. Таким образом, право
								на доступ, право на удаление, право наисправление и перенос данных не могут быть реализованы после
								истечения срока хранения. периода.
								<br />
								<br />
								Push-уведомления
								<br />
								<br />
								Мы предлагаем push-уведомления, на которые вы можете добровольно подписаться в любое время. Чтобы
								убедиться, что pushуведомления доходят до нужных устройств, мы используем стороннего поставщика
								push-уведомлений, который полагается на токен устройства, уникальный для вашего устройства, который
								выдается операционной системой вашего устройства. Хотя можно получить доступ к списку маркеров
								устройств, они не раскрывают вашу личность, уникальный идентификатор устройства или вашуконтактную
								информацию нам или нашему стороннему поставщику push-уведомлений.Мы будем хранить информациюотправленную
								по электронной почте, в соответствии с действующими законами и правилами. Если в любой момент вы
								захотите прекратить получать push-уведомления, просто измените настройки вашего устройства
								соответствующим образом.
								<br />
								Ссылки на другие ресурсы
								<br />
								<br />
								Мобильное приложение и услуги содержат ссылки на другие ресурсы, которые не принадлежат нам и не
								контролируются нами. Пожалуйста, имейте в виду, что мы не несем ответственности за практику
								конфиденциальности таких других ресурсов или третьих лиц. сторон. Мы рекомендуем вам быть внимательными,
								когда вы покидаете Мобильное приложение и Услуги, и читать заявления о конфиденциальности каждого
								ресурса, который может собирать Личную информацию.
								<br />
								<br />
								Информационная безопасность
								<br />
								<br />
								Мы храним предоставленную вами информацию на компьютерных серверах в контролируемой, безопасной среде,
								защищенной от несанкционированного доступа, использования или раскрытия. Мы поддерживаем разумные
								административные, технические и физическиемеры предосторожности для защиты от несанкционированного
								доступа, использования, изменения и раскрытия Личной информации. информации, находящейся под нашим
								контролем и опекой. Однако никакая передача данных через Интернет или беспроводную сетьне может быть
								гарантирована.
								<br />
								<br />
								Поэтому, несмотря на то, что мы стремимся защитить вашу Личную информацию, вы признаете, что (i)
								существуют ограничения безопасностии ограничения конфиденциальности в Интернете, которые находятся вне
								нашего контроля; (ii) безопасность, целостность и конфиденциальность любой и всей информации и данных,
								передаваемых между вами и Мобильным приложением и Услугамине могут быть гарантированы; и (iii) любая
								такая информация и данные могут быть просмотрены или подделаны при передаче третьей стороной, несмотря
								на все усилия. третьей стороной, несмотря на все усилия.Поскольку безопасность Личной информации
								частично зависит от безопасности устройства, которое вы используете для связи с намии безопасности,
								которую вы используете для защиты своих учетных данных, пожалуйста, примите соответствующие меры для
								защиты этой информации.информации.
								<br />
								<br />
								Нарушение безопасности данных
								<br />
								<br />
								В случае если нам станет известно, что безопасность Мобильного приложения и Услуг была нарушена или
								Личная информация Пользователей была раскрыта несвязанным третьим лицам в результате внешних
								действий,включая, но не ограничиваясь, атаками на систему безопасности или мошенничеством, мы оставляем
								за собой право принять разумно необходимыемеры, включая, но не ограничиваясь, расследование и
								отчетность, а также уведомление правоохранительных органов и сотрудничество с ними. сотрудничество с
								правоохранительными органами. В случае утечки данных мы предпримем разумные усилия чтобы уведомить
								пострадавших лиц, если мы считаем, что существует разумный риск причинения вреда Пользователю в
								результатеили если уведомление требуется по закону. В этом случае мы разместим уведомление в
								Мобильномприложении, свяжемся с вами по телефону.
								<br />
								<br />
								Изменения и поправки
								<br />
								<br />
								Мы оставляем за собой право изменять настоящую Политику или ее условия, связанные с Мобильным
								приложением и Услугами, в любое время по нашему усмотрению. В этом случае мы указываем обновленную дату
								в нижней части этой страницы. Мы также можемуведомлять вас другими способами по нашему усмотрению,
								например, через предоставленную вами контактную информацию.предоставленную вами.
								<br />
								<br />
								Обновленная версия настоящей Политики вступает в силу немедленно после публикации пересмотренной
								Политики, если не указано иное.если не указано иное. Ваше дальнейшее использование Мобильного приложения
								и Услуг после даты вступления в силу (или иного действия, указанного на тот момент) будет означать ваше
								согласие с этими изменениями.Тем не менее, без вашего согласия мы не будем использовать вашу Личную
								информацию способом, существенно отличающимся от того, который был указан в момент вашего согласия. чем
								было заявлено на момент сбора вашей личной информации.
								<br />
								<br />
								Принятие данной политики
								<br />
								<br />
								Вы подтверждаете, что ознакомились с настоящей Политикой и согласны со всеми ее положениями и условиями.
								Получая доступ и используя Мобильное приложение и Услуги, а также предоставляя свою информацию, вы
								соглашаетесь с данной Политикой. Политикой. Если вы не согласны соблюдать условия настоящей Политики, вы
								не имеете права получать доступ или использоватьМобильным приложением и Услугами.Обращение к нам
								<br />
								<br />
								Если у вас есть какие-либо вопросы, опасения или жалобы относительно настоящей Политики, информации,
								которую мы храним о вас, или если вы хотите воспользоваться своими правами, мы рекомендуем вам связаться
								с нами, используя приведенную ниже информацию:
								<br />
								<br />
								openraystech@gmail.com
								<br />
								<br />
								Мы постараемся разрешить жалобы и споры и приложим все разумные усилия, чтобы удовлетворить ваше желание
								осуществить ваши права как можно быстрее и в любом случае в сроки, предусмотренные действующимзаконами о
								защите данных.
								<br />
								<br />
								Этот документ был последний раз обновлен 1 октября 2024 года
							</code>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
