import { StoreAndroidAppUrl, StoreIOSAppUrl } from 'const'
import React from 'react'
import { isIOS, isMacOs } from 'react-device-detect'

export default function MobileStoreRedirectView() {
	const isAppleDevice = isIOS || isMacOs
	if (isAppleDevice) {
		window.location.href = StoreIOSAppUrl
		return <div></div>
	}
	window.location.href = StoreAndroidAppUrl
	return <div></div>
}
