import React from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import { Button } from 'components/ui/button'
import { StoreAndroidAppUrl, StoreIOSAppUrl } from 'const'

export default function HomeView() {
	function openAppStoreApp() {
		window.location.href = StoreIOSAppUrl
	}

	function openGooglePlayApp() {
		window.location.href = StoreAndroidAppUrl
	}

	return (
		<div className='flex min-h-screen w-full flex-col'>
			<NavBarView />
			<div className='flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8'>
				<div className='mx-auto max-w-2xl py-2 sm:py-8 lg:py-8 xl:py-8 2xl:py-16'>
					<div className='text-center'>
						<a
							className='border border-zinc-300 dark:border-zinc-800 shadow mx-auto inline-flex items-center justify-between px-1 py-1 pr-4 mb-8 text-sm text-gray-700 rounded-full dark:text-white hover:bg-zinc-100 dark:hover:bg-zinc-800'
							role='alert'
							href='https://t.me/foodtwosteps'>
							<span className='text-xs bg-blue-700 dark:bg-blue-600 rounded-full text-white px-4 py-1.5 mr-3'>
								Новое
							</span>
							<span className='mr-2 text-sm font-medium'>Подпишись на нас в Telegram 👋</span>
							<svg
								className='w-2.5 h-2.5'
								aria-hidden='true'
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 6 10'>
								<path
									stroke='currentColor'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='m1 9 4-4-4-4'></path>
							</svg>
						</a>
						<img
							className='bg-white mx-auto mb-10 h-52 content-center'
							style={{ padding: '4px', borderRadius: '40px' }}
							src='/image/icon_rounded.png'
							alt=''
						/>
						<h1 className='text-4xl font-bold tracking-tight sm:text-6xl'>Вкусные места рядом</h1>
						<p className='mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400'>
							Откройте вкусные кафе и рестораны рядом! Наслаждайтесь изысканными блюдами и фирменными напитками всего в
							нескольких шагах.
						</p>
						<div className='mt-10 flex flex-wrap gap-5 items-center justify-center gap-x-6'>
							<Button className='w-full p-7 sm:w-48' onClick={openAppStoreApp}>
								<svg
									className='mr-3 w-8 h-8'
									aria-hidden='true'
									focusable='false'
									data-prefix='fab'
									data-icon='apple'
									role='img'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 384 512'>
									<path
										fill='currentColor'
										d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'></path>
								</svg>
								<div className='text-left mr-3'>
									<div className='mb-1 text-xs'>Загрузить в</div>
									<div className='-mt-1 font-sans text-sm font-semibold'>App Store</div>
								</div>
							</Button>

							<Button className='w-full p-7 sm:w-48' onClick={openGooglePlayApp}>
								<svg
									className='mr-3 w-6 h-6'
									aria-hidden='true'
									focusable='false'
									data-prefix='fab'
									data-icon='google-play'
									role='img'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 512 512'>
									<path
										fill='currentColor'
										d='M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z'></path>
								</svg>
								<div className='text-left mr-3'>
									<div className='mb-1 text-xs'>Доступно в</div>
									<div className='-mt-1 font-sans text-sm font-semibold'>Google Play</div>
								</div>
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className='text-balance my-4 text-center text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 hover:[&_a]:text-primary'>
				By downloading our app, you agree to our Terms of Service and{' '}
				<a href={'https://foodtwosteps.com/privacy'}>Privacy Policy</a>.
			</div>
		</div>
	)
}
