import React from 'react'
import NavBarView from '../navigation/NavigationBarView'

export default function PrivatePolicyEnView() {
	return (
		<div className='flex min-h-screen w-full flex-col'>
			<NavBarView />
			<div className='container px-5 mx-auto xl:px-5 max-w-screen-lg !pt-0'>
				<div className='relative sm:rounded-lg overflow-hidden p-5 px-5 container mx-auto max-w-screen-lg mb-32'>
					<div className='w-full max-w-screen-xl'>
						<div className='relative overflow-hidden border sm:rounded-lg p-5'>
							<code className='block'>
								<p>Privacy Policy</p>
								<br />
								We respect your privacy and are committed to protecting it in accordance with this Privacy Policy (the
								Policy). This Policy outlines the types of information that we may collect from you or that you may
								provide (Personal Information) in the FoodTwoSteps mobile application (the Mobile Application or
								Service) and any related products and services (hereinafter referred to as Services), as well as our
								methods for collecting, using, maintaining, protecting, and disclosing such Personal Information. It
								also describes your rights regarding the use of your Personal Information, and how you can access and
								update it.
								<br />
								<br />
								This Policy constitutes a legally binding agreement between you (the User, you, or your) and the
								developer of the mobile application (the Operator, we, us, or our). If you are entering into this Policy
								on behalf of a business or another legal entity, you represent that you have the authority to bind such
								entity to this Policy, in which case the terms User, you, or your shall refer to such legal entity. If
								you do not have such authority or if you do not agree with the terms of this Policy, you should not
								accept this Policy and you may not access or use the Mobile Application or Services. By accessing and
								using the Mobile Application and Services, you acknowledge that you have read, understood, and agree to
								comply with the terms of this Policy. This Policy does not apply to the practices of companies that we
								do not own or control, or to individuals whom we do not employ or manage.
								<br />
								<br />
								<p>Automatic Information Collection</p>
								<br />
								When you use the Mobile Application, our servers automatically record information sent by your device.
								This data may include such information as your devices IP address and location, device name and version,
								operating system type and version, language preferences, information you search for in the Mobile
								Application, access time and date, and other statistical data.
								<br />
								<br />
								The information collected automatically is used solely for identifying potential cases of abuse and
								generating statistical information about the usage of the Mobile Application and Services. This
								statistical information is not aggregated in a way that would identify a specific User.
								<br />
								<br />
								Collection of Personal Information
								<br />
								<br />
								You can access and use the Mobile Application and Services without providing us with information that
								identifies you as a specific, identifiable person. However, if you wish to take advantage of certain
								features offered in the Mobile Application, you may be asked to provide certain personal information
								(e.g., your name and email address).
								<br />
								<br />
								We collect and store any information you knowingly provide to us when creating an account, posting
								content, or completing any forms in the Mobile Application. This information may include the following:
								<br />
								<br />
								- Account data (e.g., username, unique user ID, password, etc.) д.)
								<br />
								- Contact information (e.g., email address, etc.)
								<br />
								- Basic personal information (e.g., name, country of residence, etc.)
								<br />
								- Geolocation data of your device (e.g., latitude and longitude)
								<br />
								- Certain mobile device features (e.g., contacts, calendar, gallery, etc.)
								<br />
								<br />
								You are not required to provide us with your personal information, but if you do not, you may not be
								able to use certain features of the Mobile Application. Users who are unsure about what information is
								mandatory can contact us.
								<br />
								<br />
								Childrens Privacy
								<br />
								<br />
								We do not knowingly collect personal information from children under the age of 18. If you are under 18,
								please do not provide personal information through the Mobile Application and Services. If you believe
								that a child under the age of 18 has provided us with personal information through the Mobile
								Application and Services, please contact us and request the deletion of that childs personal information
								from our Services.
								<br />
								<br />
								We encourage parents and legal guardians to monitor their childrens use of the internet and help enforce
								this Policy by instructing their children never to provide personal information through the Mobile
								Application and Services without their permission. We also ask all parents and legal guardians
								supervising children to take necessary precautions to ensure their children are instructed never to
								provide personal information online without their consent.
								<br />
								<br />
								Use and Processing of Collected Information
								<br />
								<br />
								We act as both a data controller and processor when handling Personal Information, except in cases where
								we have entered into a data processing agreement with you, in which case you will be the data controller
								and we will be the processor.
								<br />
								<br />
								Our role may also differ depending on the specific situation involving Personal Information. We act as a
								data controller when we ask you to provide Personal Information necessary for your access to and use of
								the Mobile Application and Services. In these cases, we are the data controller because we determine the
								purposes and means of processing the Personal Information.
								<br />
								<br />
								We act as a data processor in situations where you provide Personal Information through the Mobile
								Application and Services. We do not own, control, or make decisions about the provided Personal
								Information. This information is processed only according to your instructions. In these cases, the User
								providing the Personal Information is the data controller.
								<br />
								<br />
								To make the Mobile Application and Services available to you, or to fulfill legal obligations, we may
								need to collect and use certain Personal Information. If you do not provide the requested information,
								we may not be able to provide you with the requested products or services. Any information we collect
								from you may be used for the following purposes:
								<br />
								<br />
								- Creating and managing user accounts
								<br />
								- Sending product and service updates
								<br />
								- Ensuring compliance with terms and policies
								<br />
								- Operating and maintaining the Mobile Application and Services
								<br />
								<br />
								The processing of your Personal Information depends on how you interact with the Mobile Application and
								Services, your location in the world, and if any of the following conditions apply: (i) you have given
								your consent for one or more specific purposes; (ii) providing the information is necessary to perform
								this Policy with you and/or to fulfill pre-contractual obligations; (iii) processing is necessary for
								compliance with a legal obligation to which you are subject; (iv) processing is related to a task
								carried out in the public interest or in the exercise of official authority vested in us; (v) processing
								is necessary for the legitimate interests pursued by us or a third party. We may also combine or
								aggregate some of your personal data to better serve you and improve and update our Mobile Application
								and Services.
								<br />
								<br />
								Please note that under certain laws, we may be allowed to process information until you object to such
								processing by opting out, without relying on consent or any other legal basis. In any case, we will be
								happy to explain the specific legal basis that applies to processing and, in particular, whether
								providing personal information is a requirement of the law or contract or a necessary condition for
								entering into a contract.
								<br />
								<br />
								Managing Information
								<br />
								<br />
								You may delete certain personal data we hold about you. The personal information you can delete may
								change as the Mobile Application and Services evolve. However, when you delete Personal Information, we
								may retain a copy of your unreviewed Personal Information in our records for as long as necessary to
								fulfill our obligations to our affiliates and partners and for the purposes outlined below. If you wish
								to delete your Personal Information or permanently delete your account, you can do so on your account
								settings page in the Mobile Application.
								<br />
								<br />
								Disclosure of Information
								<br />
								<br />
								Depending on the requested services or if necessary to complete any transaction or provide any service,
								we may transfer your information to our contractors and service providers (hereinafter Service
								Providers), on whom we rely to assist in the operation of the Mobile Application and Services available
								to you, and whose privacy policies are in line with ours or who agree to comply with our policies
								regarding Personal Information. We will not disclose personal information to third parties and will not
								share any information with non-affiliated third parties.
								<br />
								<br />
								Service Providers are not permitted to use or disclose your information except when necessary to perform
								services on our behalf or to comply with legal requirements. Service Providers only receive the
								information they need to perform their functions, and we do not allow them to use or disclose any
								provided information for their own marketing or other purposes.
								<br />
								<br />
								Retention of Information
								<br />
								<br />
								We will retain and use your Personal Information for the period necessary to fulfill our legal
								obligations, as long as your user account remains active, to ensure compliance with our Policy, resolve
								disputes, and if a longer retention period is required or permitted by law.
								<br />
								<br />
								We may use any aggregated data obtained from or including your Personal Information after you update or
								delete it, but not in a way that would personally identify you. After the retention period expires,
								Personal Information must be deleted. Therefore, the rights to access, delete, correct, and transfer
								data cannot be exercised after the retention period.
								<br />
								<br />
								Push Notifications
								<br />
								<br />
								We offer push notifications that you can voluntarily subscribe to at any time. To ensure that push
								notifications reach the correct devices, we use a third-party push notification provider that relies on
								a device token unique to your device, issued by your devices operating system. While it is possible to
								access a list of device tokens, they do not disclose your identity, devices unique identifier, or
								contact information to us or our third-party push notification provider. We will retain information sent
								via email in accordance with applicable laws and regulations. If at any time you wish to stop receiving
								push notifications, simply change the settings on your device accordingly.
								<br />
								Links to Other Resources
								<br />
								<br />
								The Mobile Application and Services contain links to other resources that are not owned or controlled by
								us. Please be aware that we are not responsible for the privacy practices of such other resources or
								third parties. We encourage you to be cautious when leaving the Mobile Application and Services and to
								read the privacy statements of any resource that may collect Personal Information.
								<br />
								<br />
								Information Security
								<br />
								<br />
								We store the information you provide on computer servers in a controlled, secure environment, protected
								from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and
								physical safeguards to protect against unauthorized access, use, alteration, and disclosure of Personal
								Information under our control. However, no data transmission over the Internet or wireless network can
								be guaranteed to be completely secure.
								<br />
								<br />
								Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are
								security and privacy limitations on the Internet that are beyond our control; (ii) the security,
								integrity, and privacy of any and all information and data exchanged between you and the Mobile
								Application and Services cannot be guaranteed; and (iii) any such information and data may be viewed or
								tampered with by a third party during transmission, despite all efforts. Since the security of Personal
								Information depends partially on the security of the device you use to communicate with us and the
								security you use to protect your credentials, please take appropriate measures to protect this
								information.
								<br />
								<br />
								Data Security Breach
								<br />
								<br />
								In the event we become aware that the security of the Mobile Application and Services has been breached
								or Personal Information of Users has been disclosed to unrelated third parties as a result of external
								actions, including, but not limited to, system attacks or fraud, we reserve the right to take reasonable
								measures, including, but not limited to, investigation and reporting, as well as notifying law
								enforcement and cooperating with them. In the event of a data breach, we will make reasonable efforts to
								notify affected individuals if we believe there is a reasonable risk of harm to the User or if
								notification is required by law. In this case, we will post a notification in the Mobile Application and
								contact you by phone.
								<br />
								<br />
								Changes and Amendments
								<br />
								<br />
								We reserve the right to change this Policy or its terms related to the Mobile Application and Services
								at any time at our discretion. In such cases, we will indicate the updated date at the bottom of this
								page. We may also notify you by other means at our discretion, such as through the contact information
								you have provided.
								<br />
								<br />
								Updated Version of the Policy
								<br />
								<br />
								The updated version of this Policy comes into effect immediately after the publication of the revised
								Policy, unless otherwise stated. Your continued use of the Mobile Application and Services after the
								effective date (or any other action specified at that time) will signify your acceptance of these
								changes. However, without your consent, we will not use your Personal Information in a way that
								significantly differs from what was stated at the time of collecting your personal data.
								<br />
								<br />
								Acceptance of this Policy
								<br />
								<br />
								You confirm that you have read and agree with all the provisions and terms of this Policy. By accessing
								and using the Mobile Application and Services, as well as providing your information, you agree to this
								Policy. If you do not agree to comply with the terms of this Policy, you are not allowed to access or
								use the Mobile Application and Services.
								<br />
								<br />
								Contacting Us
								<br />
								<br />
								If you have any questions, concerns, or complaints regarding this Policy, the information we hold about
								you, or if you wish to exercise your rights, we encourage you to contact us using the information below:
								<br />
								<br />
								openraystech@gmail.com
								<br />
								<br />
								We will strive to resolve any complaints or disputes and make reasonable efforts to fulfill your desire
								to exercise your rights as quickly as possible and in accordance with applicable data protection laws.
								<br />
								<br />
								This document was last updated on October 1, 2024.
							</code>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
